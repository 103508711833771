<template>
  <div class="fan-art d-flex align-items-center justify-content-between position-relative" @click="clicked">
    <div class="w-100 h-100 d-flex align-items-center justify-content-between" :style="{ background }">
      <img
        loading="lazy"
        v-fan-art-image
        :alt="alt"
        :src="resizeUpload(image, '250x250')"
        @click="openVisualPreviewModal"
      />
    </div>

    <div class="overlay w-100">
      <div class="title">
        {{ title }}
      </div>
      <div class="mt-1 username" v-if="username">
        by
        <router-link class="username bold" :to="{ name: 'profile', params: { username } }">
          {{ username }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  fanArt: {
    type: Object,
    default: () => ({}),
  },
  background: {
    type: String,
    default: '',
  },
});

const fanArt = computed<any>(() => toRef(props.fanArt).value as any);
const image = computed(() => fanArt.value.image || '');
const title = computed(() => fanArt.value.title || '');
const alt = computed(() => fanArt.value.alt || '');
const username = computed(() => fanArt.value.username || '');

const emits = defineEmits(['click']);

const clicked = () => emits('click');

const openVisualPreviewModal = () => {};
</script>

<style lang="sass" scoped>
.fan-art
  aspect-ratio: 0.7
  border-radius: 12px
  overflow: hidden
  cursor: pointer
  &:hover
    opacity: 0.8
  img
    width: 100%
    height: 100%
    min-width: 100px
    object-fit: cover
  .overlay
    position: absolute
    bottom: 0
    background: rgba(10, 9, 40, 0.8)
    padding: 6px
    .title
      font-weight: bold
      font-size: 0.75rem
      overflow: hidden
      text-overflow: ellipsis
      width: 100%
      -webkit-line-clamp: 1
      -webkit-box-orient: vertical
      display: -webkit-box
      color: #FFF !important
    .username
      font-size: 0.625rem
      color: #FFF !important
      &.bold
        font-weight: 700
</style>
