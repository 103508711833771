<template>
  <Grid :scrollSm="false" :lg="3" :sm="2">
    <BriefFanArt
      v-for="(fanArt, index) of mappedFanArts"
      :key="index"
      class="one-fan-art grid-item"
      :fan-art="fanArt"
      :background="colors[colorSelect(index)]"
      @click="openVisualPreview(index)"
    />
    <VisualPreviewModal
      :image-url="selectedVisualImg"
      :image-id="selectedVisualImgId"
      :caption="selectedVisualCaption"
      :source="selectedVisualSource"
      :all-images="fanArts"
      :is-featured="true"
      :active-index="selectedIndex"
      :is-open="openVisualPreviewModal"
      @dismiss-modal="closeVisualPreviewModal"
    />
  </Grid>
</template>

<script lang="ts" setup>
import BriefFanArt from './brief-fan-art.vue';
import Grid from '@/shared/components/storage/Grid.vue';
import VisualPreviewModal from '@/shared/modals/VisualPreviewModal.vue';

const props = defineProps({
  fanArts: {
    type: Array,
    default: () => [],
  },
});

const selectedVisualImg = ref('');
const selectedVisualImgId = ref('');
const selectedVisualCaption = ref('');
const selectedVisualSource = ref('');
const openVisualPreviewModal = ref(false);
const selectedIndex = ref(0);

const fanArts = computed<any[]>(() => toRef(props.fanArts).value as any[]);

const mappedFanArts = computed(() =>
  fanArts.value
    .map(({ character, image, visual_url: url }) => ({
      'image': url,
      alt: `${character.info.name} - original character art`,
      title: image.description || character?.info?.name || '',
      username: image.username || character?.author?.username || '',
    }))
    .filter(({ image }) => image)
);

const colors = ['#00B4C5', '#8DA6C1', '#FF3864', '#C59FFF', '#45B6F5'];

const colorSelect = (index: number) => {
  const remaining = Math.round((index / 5 - Math.floor(index / 5)) * 100) / 100;
  return remaining * 5;
};

const openVisualPreview = (index: number) => {
  const img = fanArts.value[index].image;
  selectedVisualImg.value = fanArts.value[index].visual_url;
  selectedVisualImgId.value = img ? img.id || '' : '';
  selectedVisualCaption.value = img ? img.description || '' : '';
  selectedVisualSource.value = img ? img.source || '' : '';
  selectedIndex.value = index;
  openVisualPreviewModal.value = true;
};

const closeVisualPreviewModal = () => {
  openVisualPreviewModal.value = false;
  selectedVisualImg.value = '';
  selectedVisualImgId.value = '';
  selectedVisualCaption.value = '';
  selectedVisualSource.value = '';
};
</script>

<style lang="sass" scoped></style>
